import React, { Component } from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import PostCard from '../components/PostCard'
import Layout from '../components/Layout'

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='px-4 py-2 font-bold text-white rounded g-blue-500 hover:bg-blue-700'>
        {props.text}
      </span>
    )
  }
}

export default class BlogPage extends Component {
  render () {
    const { pageContext } = this.props
    const { group, index, first, last } = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    return (
      <Layout includePreFooter>
        <div className='bg-white flex flex-col'>
          <div className='container mx-auto px-8'>
            <Helmet>
              <title>Blocktag Blog</title>
              {/* Schema.org tags */}
              <script type='application/ld+json'>
                {JSON.stringify(websiteSchemaOrgJSONLD)}
              </script>
            </Helmet>

            {/* <main className="flex flex-wrap items-center text-center md:text-left my-6 py-24 lg:px-24">
              <div className="w-full md:w-full whitespace-pre-line">
                <h1 className="text-3xl text-black leading-tight mb-6">
                  Blog
                </h1>
              </div>
            </main> */}

            <section className='flex flex-wrap my-12 mb-4'>
              <PostCard posts={group} />
            </section>

            <section className='section'>
              <div className='buttons is-centered'>
                <PaginationLink test={first} url={previousUrl} text='Previous Page' />
                <PaginationLink test={last} url={nextUrl} text='Next Page' />
              </div>
            </section>

          </div>
        </div>
      </Layout>
    )
  }
}
