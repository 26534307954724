import React from 'react'
import { Link } from 'gatsby'

const PostCard = ({ posts }) => {
  return (
    posts
      .filter(post => post.node.frontmatter.templateKey === 'article-page')
      .map(({ node: post }) => (
        <div className='w-1/3 p-4'>
          <div className='shadow-lg rounded overflow-hidden'>
            <Link className='has-text-primary' to={post.fields.slug} key={post.id}>
              <img className='w-full' src={post.frontmatter.cover} alt={post.frontmatter.title} />
              <div className='px-6 py-4'>
                <div className='font-bold text-xl mb-2'>{post.frontmatter.title}</div>
                <p className='text-gray-700 text-base'>
                  {post.excerpt}
                </p>
              </div>
              <div className='px-6 py-4'>
                {post.frontmatter.tags.map(tag => (
                  <span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 last:mr-0'>#{tag}</span>
                ))}
              </div>
              <div className='px-6 py-4'>
                {/* <img className="w-10 h-10 rounded-full mr-4" src="/img/jonathan.jpg" alt="Avatar of Jonathan Reinink" /> */}
                <div className='text-sm'>
                  <p className='text-gray-900 leading-none'>Blocktag Team</p>
                  <p className='text-gray-600'>{post.frontmatter.date}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ))
  )
}

export default PostCard
